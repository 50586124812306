* {
    box-sizing: border-box;
  }
  .wrap {
    width: 640px;
    margin: 50px auto;
  }
  
  .field {
    position: relative;
    margin-bottom: 10px;
    input, textarea {
      width: 100%;
      display: block;
      outline: none;
      padding: 5px;
      &:focus {
        border: 2px solid #eb7b03;
      }
      &:focus + label {
        display: block;
      }
    }
    textarea {
      height: 150px;
    }
    label {
      width: 100%;
      background-color: #eb7b03;
      display: block;
      color: #fff;
      padding: 3px;
      font-size: 12px;
      font-weight: 300;
      display: none;
    }
  }
  h2 {
    font-weight: 300;
  }
  
  .small {
    width: 32%;
    float: left;
    margin-left: 2%;
    &:first-child {
      margin-left: 0%;
    }
  }
  .large {
    float:left;
    width: 100%;
  }
  
  ::-webkit-input-placeholder {
     font-weight: 300;
  }
  :-moz-placeholder { /* Firefox 18- */
     font-weight: 300; 
  }
  ::-moz-placeholder {  /* Firefox 19+ */
     font-weight: 300; 
  }
  :-ms-input-placeholder {  
     font-weight: 300; 
  }