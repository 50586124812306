h2{
    padding-top: 1.5rem;
  }
  a{
    color: #333;
  }
  a:hover{
    color: #008080;
    text-decoration: none;
  }
  .card{
    border: 0.40rem solid #008080;
    top: 10%;
  }