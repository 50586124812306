.search-box{
	padding:3em;
	height:100px;
	background-color: #8e8e8e;
  margin-bottom:20px;
  margin-top:0px;
  align-items: center;
  justify-content: center;
}
#input-label {
  display: none;
}

.input-wrap {
  width: 80%;
  height: 3.0rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem 0 1rem;
  border-radius: 0.8rem;
  /*background: rgb(238, 235, 235);*/
  box-sizing: border-box;
}

.input-wrap i {
  font-size: 1.0rem;
  color: white;
}

#skill-search {
  font-size: 0.9rem;
  color: rgb(54, 48, 48);
  width: 100%;
  height: 100%;
  padding: 0 0 0 1rem;
  border: 1px solid #008080;
  border-radius: 60px;
  outline:none;
}

#skill-search::placeholder {
  color: rgb(17, 15, 15);
  opacity: 0.7;
}
.clear-icon{
  background-color: #008080;
  color:white;
  font-size: medium;
}

.fa-times:hover {
  cursor: pointer;
}

.results-wrap {
  width: 50%;
  min-height: 11rem;
  max-height: auto;
  margin-top: 1rem;
  padding: 1rem;
  box-sizing: border-box;
}

.list-item {
  display: inline-flex;
  word-wrap: initial;
  list-style-type: none;
  font-size: 1.2rem;
  line-height: 1.5rem;
  text-transform: capitalize;
  margin-right: 0.5rem;
}

.list-item a {
  color: grey;
  text-decoration: none;
}

.list-item a:hover {
  color: tomato;
  transition: 200ms ease-in;
}

@media (max-width: 768px) {
  .input-wrap {
    width: 96%;
  }
  
  .results-wrap {
    width: 96%;
  }
}