.adminNavbarWrapper
{
    font-size: 15px;
    font-family: Raleway Light,Helvetica,Arial;
    line-height: 1.42857;
    font-weight: lighter;
}
p  /*Personalizado Bootstrap*/
{
  font-size: 15px;
  font-style: normal;
  color: #333333;
  line-height: 1.42857;
}


ul, ol /*Padrão Bootstrap*/
{
  margin-top: 0px;
  margin-bottom: 10px;
  background-color: none;
}

.nav /*Padrão Bootstrap*/
{
  padding-left: 0px;
  margin-bottom: 0px;
  list-style-type: none;
  list-style-image: none;
  list-style-position: outside;
}
.nav,
.nav-tabs {
  background-color: #031324;
   
  height: 70px;
  color: white;
}
.nav-tabs > li /*Padrão Bootstrap*/
{
  float: left;
  margin-bottom: -1px;
}
nav,
.nav-tabs > li > a {
  color: #c5bdbd; 
  text-decoration-line: none;
  position: relative;
  display: block;
  padding-top: 25px;
  padding-right: 15px;
  padding-bottom: 24px;
  padding-left: 20px;
  background-color: transparent;
}
nav,
.nav-tabs > li.active a,
nav,
.nav-tabs > li.active a:hover,
nav,
.nav-tabs > li.active a:focus {
  background-color: #008080;
  color: #fff;
  text-decoration-line: none;
}
.tab-content {
  color: white;
  background-color: #e6e6e6;
  padding: 5px 15px;
}
.tab-content > .tab-pane /*Padrão Bootstrap*/
{
  display: none;
}
.tab-content > .active /*Padrão Bootstrap*/
{
  display: block;
}

li.active a:before{
  border-color: #008080 transparent transparent transparent;
  border-style: solid;
  border-width: 10px 10px 0px 10px;
  content: "";
  display: block;
  left: calc(50% - 10px);
  position: absolute;
  width: 0px;
  top: 70px;
   z-index: 10;
}

/* Sub-Menus*/
.subMenuWrapper{
  color: #031324;
  display: flex;
  -webkit-display: flex;
  -moz-display: flex;
  -ms-display: flex;
  -o-display: flex;

 

}
.subMenuWrapper a{
  color: #031324;
}

.subMenuWrapper a,a:link,a:hover{
  text-decoration:none;
}

.subMenuWrapper ul,li{
	list-style: none;
	padding: 0px;
	margin: 0px;
}

.mul {
  width: 100%;
  display: inline-flex;
  -webkit-display: inline-flex;
  -moz-display: inline-flex;
  -ms-display: inline-flex;
  -o-display: inline-flex;
  justify-content: space-evenly;
  -webkit-justify-content: space-evenly;
  -moz-justify-content: space-evenly;
  -ms-justify-content: space-evenly;
  -o-justify-content: space-evenly;
  align-items: center;
}

.mul>li{
	padding: 23px 0px;
    cursor: pointer;
	font-size: 16px;
}

.down{
	width: 100%;
	display: flex;
	position: absolute;
  z-index: 1;

}

.second-down{
	width: 100%;
	display: flex;
	position: absolute;
  position: relative;
  left: 20px;
  top: 10px;

}

.down ul li {
	position: relative;
	background-color: #c8ced2;
	padding: 5px 1.1vw;
	border-bottom: 1px solid #1a1c33;
	border-right: 1px solid #1a1c33;
	border-left: 1px solid #1a1c33;
	cursor: pointer;
}

@-webkit-keyframes apple {
  0%   {color: black;}
  25%  {color: #8200a2;}
  50%  {color: #1a1c33;}
  75%  {color: #8200a2;}
  100% {color: black;}
}

.fa-apple{
-webkit-animation: apple 10s infinite linear;
}


.first{
position:relative;
visibility: hidden;
opacity: 0;
transition: all 0.5s;
perspective: 200px;
transform: rotateY(180deg);
}

.second{
position:absolute;
top:50%;
left:9.6%;
visibility: hidden;
opacity: 0;
transition: all 0.5s;
perspective: 200px;
transform: rotateY(180deg);
}

.third{
position:absolute;
top:150%;
left: 19.2%;
visibility: hidden;
opacity: 0;
transition: all 0.5s;
perspective: 200px;
transform: rotateY(180deg);
}

.addshow{
	visibility: visible;
	transition: all 0.5s;
	opacity: 1;
	perspective: 200px;
	transform: rotateY(360deg);
	/*backface-visibility: hidden;*/
}

.first:hover{
	visibility: visible !important;
	opacity: 1;
        perspective: 200px;
	transform: rotateY(360deg);
}

.second:hover{
	visibility: visible !important;
	opacity: 1;
    perspective: 200px;
	transform: rotateY(360deg);
}

.third:hover{
	visibility: visible !important;
	opacity: 1;
    perspective: 200px;
	transform: rotateY(360deg);
}





