@import url('https://fonts.googleapis.com/css?family=PT+Sans');
@import url('https://fonts.googleapis.com/css?family=PT+Sans');


  .top-wrapper{
    width:100%;
    /*background-color: #7fdc68;*/
    background-color: #f4f4f4;
    font-family: 'PT Sans', sans-serif;
    line-height: 1.5;
    font-size: 55px;
    box-sizing: border-box;
    margin:0;
    padding:0;
  }

  .top-wrapper p{
    font-size: 24px;
  }
  

 h1,h2,h3{
  padding: 15px;
  color: #008080;
  background-color: transparent;
  opacity: 0.8;
}
.card{
  width: 100%;
}
.card  h1,h2,h3{
  
  width: 80%;
  }
.card  h1,h2,h3{
/*color:#fff;*/
width: 100%;
}

a {
  color:#008080;
  background-color: transparent;
  font-style:none;
}
a:hover{
  color:#989461;
}
.link-anchor{
	color:#008080;
	background-color: transparent;
	font-style:none;
  }

/** Forms **/
form{
	padding: 0 2em;
}
.form-container {
  width: 80%;
  margin-top: 25px;
  position: relative;
  left:0px;
  right:0px;
  align-items: center;
}
.form-control{
  background-color: #f8f9fa;
  padding: 20px;
  padding: 25px 15px;
  margin-bottom: 1.3rem;
}

.form-control:focus {

    color: #000000;
    background-color: #ffffff;
   /* border: 3px solid #008080;*/
    outline: 0;
    box-shadow: none;

}
input{
	width: 100%;
	border:0px solid transparent;
	border-radius: 0;
	border-bottom: 1px solid #aaa;
	padding: 1em .5em .5em;
	padding-left: 2em;
	outline:none;
	margin:1.5em auto;
	transition: all .5s ease;
  margin-bottom:40px;
}
input:focus{
	transition: ease-in-out, width .35s ease-in-out;
  color:#008080;
  border:2px solid #008080
}

input[type=text]:focus {
   transition: ease-in-out, width .35s ease-in-out;
  color:#008080;
  border:2px solid #008080
}

.select {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: white;
  border: 1px lightgray solid;
  border-radius: 0;
  cursor: pointer;
  padding: 12px;
  width: 100%;
  font-size: 18px;
  margin-bottom: 40px;
  
}
.select:focus{
  color:#008080;
  border: 2px solid #008080;
}
button {
  border:none;
  cursor: pointer;
  border-radius: 12px;            
  padding: 10px 18px;   
  margin:15px;
  /*border-radius: 30px;
	color:#fff;
	font-weight: 600;
	background-color: #008080;
	border: 1px solid #008080;
	margin-top: 1.5em;
	margin-bottom: 1em;*/
}
.submit-btn{
	/*transition: all .5s ease;*/
	width: 70%;
	border-radius: 30px;
	color:#fff;
	font-weight: 600;
	background-color: #008080;
	border: 1px solid #008080;
	margin-top: 1.5em;
	margin-bottom: 1em;

}
.submit-btn:hover, .submit-btn:focus{
	background-color: #fff;
	color:#008080;
}
label {
    font-weight: 600;
    color: #555;
}


 .highlight{
  color:rgb(225, 203, 37) ;
  font-weight: bold;
}
.main {
  display: block;
  position: relative;
  padding-left: 45px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 20px;
}


/* Style Checkboxes */
  /* Hide the default checkbox */
  /*input[type=checkbox] {
    visibility: hidden;
}*/

 /* Creating a custom checkbox
        based on demand */
        .csmark {
          position: absolute;
          top: 0;
          left: 0;
          height: 25px;
          width: 25px;
          background-color:rgb(66, 63, 63);
      }
    /* Specify the background color to be
        shown when hovering over checkbox */
      /*  .main:hover input ~ .cskmark {
          background-color: yellow;
      }*/
      .csmark:hover{
        background-color: yellow;
      }
        
      /* Specify the background color to be
      shown when checkbox is active */
     .main input:active ~ .cskmark {
          background-color: #008080;
      }
    
        
      /* Specify the background color to be
      shown when checkbox is checked */
      .main input:checked ~ .cskmark {
          background-color: green;
      }

    
        
      /* Checkmark to be shown in checkbox */
      /* It is not be shown when not checked */
      .cskmark:after {
          content: "";
          position: absolute;
          display: none;
      }
        
      /* Display checkmark when checked */
      .main input:checked ~ .cskmark:after {
          display: block;
      }
        
      /* Styling the checkmark using webkit */
      /* Rotated the rectangle by 45 degree and 
      showing only two border to make it look
      like a tickmark */
      .main .cskmark:after {
          left: 8px;
          bottom: 5px;
          width: 6px;
          height: 12px;
          border: solid white;
          border-width: 0 4px 4px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
      }

      /* Search Box **/
      .search-input-wrap{
        width: 80%;
        height: 3.0rem;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 1rem 0 1rem;
        border-radius: 0.8rem;
        /*background: rgb(238, 235, 235);*/
        box-sizing: border-box;

      }
      .input-wrap i {
        font-size: 1.0rem;
        color: white;
      }
      
      #search-input {
        font-size: 0.9rem;
        color: rgb(54, 48, 48);
        width: 100%;
        height: 100%;
        padding: 0 0 0 1rem;
        border: 1px solid #008080;
        border-radius: 60px;
        outline:none;
      }
      
      #search-input::placeholder {
        color: rgb(17, 15, 15);
        opacity: 0.7;
      }
      .clear-search-icon{
        background-color: #008080;
        color:white;
        font-size: medium;
      }
      /* Snack bar */
      .snackbar{
        position:relative;
        right:2%;
        bottom: 2%;
        font-size: medium;

      }
      .snackbar-success{
        background-color: #008080;
        color:white;
      }
      .snackbar-warning{
        background-color: chocolate;
        color:white;

      }
      .snackbar-error{
        background-color: brown;
        color:white

      }
  .modalRoot {
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100vh;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.2);
}

/* Accounting **/
#page-header{
  height:70px;
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 20px;
  background-color: rgb(16, 15, 15);
}

#page-header h1,h2,h3{
  background-color: transparent;
  color:antiquewhite;
  padding:10px;
}
#page-header h1{
  font-size: 22px;
  float:left;
 
}


#page-header button{
  float:right;
  display:inline;
  padding: 0 20px 0 20px;
}
#page-header nav{
  float:right;
  margin-top:10px;
}


.action-btn{
 
    box-sizing: border-box;
    appearance: none;
    background-color: rgb(41, 166, 89);
    border: none;
    border-radius: 0.6em;
    color:antiquewhite;
    cursor: pointer;
    display: flex;
    align-self: center;
    font-size: 0.6rem;
    font-weight: 400;
    line-height: 1;
    margin: 10px;
    padding: 1.2em 2.8em;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700; 
    height:40px;
    width:120px;
   
  
}

.action-btn:hover{
  background-color: #989461;
    border: none;
    outline:0;
}
.action-btn:focus{
  background-color: #b4c2c2;
    border: none;
    outline:0;
    color:#008080;
}

.cancel-btn{
 
  box-sizing: border-box;
  appearance: none;
  background-color: rgb(146, 135, 135);
  border-radius: 0.6em;
  color:antiquewhite;
  cursor: pointer;
  display: flex;
  align-self: center;
  font-size: 0.6rem;
  font-weight: 400;
  line-height: 1;
  margin: 10px;
  padding: 1.2em 2.8em;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700; 
  transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
  height:40px;
  width:120px;

 

}

.cancel-btn:hover{
background-color: #989461;
  border: none;
  outline:0;
}
.cancel-btn:focus{
background-color: #b4c2c2;
  border: none;
  outline:0;
  color:#008080;
}

.import-btn{
 
  box-sizing: border-box;
  appearance: none;
  background-color: transparent;
  border: 2px solid rgb(41, 166, 89);
  border-radius: 0.6em;
  color:antiquewhite;
  cursor: pointer;
  display: flex;
  align-self: center;
  font-size: 0.6rem;
  font-weight: 400;
  line-height: 1;
  margin: 10px;
  padding: 1.2em 2.8em;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700; 
  transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
  height:40px;
  width:120px;

 

}

.import-btn:hover{
background-color: rgb(41, 166, 89);
  border: none;
  outline:0;
}
.import-btn:focus{
background-color: #b4c2c2;
  border: none;
  outline:0;
  color:#008080;
}

#page-body{
  margin-left:30px;
  margin-right:20px;
}

/* BILLS AND INVOICES*/
/* reset */



/* content editable */

*[contenteditable] { border-radius: 0.25em; min-width: 1em; outline: 0; }

*[contenteditable] { cursor: pointer; }

*[contenteditable]:hover, *[contenteditable]:focus, td:hover *[contenteditable], td:focus *[contenteditable], img.hover { background: #DEF; box-shadow: 0 0 1em 0.5em #DEF; }

span[contenteditable] { display: inline-block; }

/* heading */

#bill-and-invoices h1 { font: bold 100% sans-serif; letter-spacing: 0.5em; text-align: center; text-transform: uppercase; }

/* table */

#bill-and-invoices table { font-size: 90%; table-layout: fixed; width: 100%; }
#bill-and-invoices table { border-collapse: separate; border-spacing: 2px; }
#bill-and-invoices th, td { border-width: 1px; padding: 0.5em; position: relative; text-align: left; }
#bill-and-invoices th, td { border-radius: 0.25em; border-style: solid; }
#bill-and-invoices th { background: #EEE; border-color: #BBB; }
#bill-and-invoices td { border-color: #DDD; }

/* header */

#bill-and-invoices header { margin: 0 0 3em; }
#bill-and-invoices header:after { clear: both; content: ""; display: table; }

#bill-and-invoices header h1 { background: #000; border-radius: 0.25em; color: #FFF; margin: 0 0 1em; padding: 0.5em 0; }
#bill-and-invoices header address { float: left; font-size: 75%; font-style: normal; line-height: 1.25; margin: 0 1em 1em 0; }
#bill-and-invoices header address p { margin: 0 0 0.25em; }
#bill-and-invoices header span, header img { display: block; float: right; }
#bill-and-invoices header span { margin: 0 0 1em 1em; max-height: 25%; max-width: 60%; position: relative; }
#bill-and-invoices header img { max-height: 100%; max-width: 100%; }
#bill-and-invoices header input { cursor: pointer; -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; height: 100%; left: 0; opacity: 0; position: absolute; top: 0; width: 100%; }

/* article */

#bill-and-invoices article, article address, table.meta, table.inventory { margin: 0 0 2em; }
#bill-and-invoices article:after { clear: both; content: ""; display: table; }
#bill-and-invoices article h1 { clip: rect(0 0 0 0); position: absolute; }

#bill-and-invoices article address { float: left; font-size: 125%; font-weight: bold; }

/* table meta & balance */

#bill-and-invoices table.meta, table.balance { float: right; width: 36%; }
#bill-and-invoices table.meta:after, table.balance:after { clear: both; content: ""; display: table; }

/* table meta */

#bill-and-invoices table.meta th { width: 40%; }
#bill-and-invoices table.meta td { width: 60%; }

/* table items */

#bill-and-invoices table.inventory { clear: both; width: 100%; }
#bill-and-invoices table.inventory th { font-weight: bold; text-align: center; }

#bill-and-invoices table.inventory td:nth-child(1) { width: 26%; }
#bill-and-invoices table.inventory td:nth-child(2) { width: 38%; }
#bill-and-invoices table.inventory td:nth-child(3) { text-align: right; width: 12%; }
#bill-and-invoices table.inventory td:nth-child(4) { text-align: right; width: 12%; }
#bill-and-invoices table.inventory td:nth-child(5) { text-align: right; width: 12%; }

/* table balance */

#bill-and-invoices table.balance th, table.balance td { width: 50%; }
#bill-and-invoices table.balance td { text-align: right; }

/* aside */

#bill-and-invoices aside h1 { border: none; border-width: 0 0 1px; margin: 0 0 1em; }
#bill-and-invoices aside h1 { border-color: #999; border-bottom-style: solid; }

/* javascript */

#bill-and-invoices .add, .cut
{
	border-width: 1px;
	display: block;
	font-size: 1rem;
	padding: 0.25em 0.5em;	
	float: left;
	text-align: center;
	width: 50px;
  margin:30px;
}

#bill-and-invoices .add, .cut
{
	background: #9AF;
	box-shadow: 0 1px 2px rgba(0,0,0,0.2);
	background-image: -moz-linear-gradient(#0c6543 5%, #008080 100%);
	background-image: -webkit-linear-gradient(#0c6543 5%, #008080 100%);
	border-radius: 0.5em;
	border-color: #008080;
	color: #FFF;
	cursor: pointer;
	font-weight: bold;
	text-shadow: 0 -1px 2px rgba(0,0,0,0.333);
 
}
.bill-stats{
  margin:15px;
  background-color:lightgray;
  align-items: baseline;
  float:left;
  display: inline-flex;
  width:100%;
}
.stat-card {
  margin: 30px auto;
  width: 150px;
  height: 100px;
  border-radius: 15px;
/*box-shadow: 5px 5px 30px 7px rgba(0,0,0,0.25), -5px -5px 30px 7px rgba(0,0,0,0.22);*/
  cursor: pointer;
  transition: 0.4s;
  background-color:rgb(248, 243, 243)
}
.stat-card .card_title {
  text-align: center;
  border-radius: 0px 0px 40px 40px;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 20px;
  height: 40px;
  font-weight: bold;
}
.stat-card .sub-title{
font-size:16px;
color: black;
opacity:0.7;
text-align: center;

}


.stat-card:hover {
  transform: scale(0.9, 0.9);
  box-shadow: 5px 5px 30px 15px rgba(0,0,0,0.25), 
    -5px -5px 30px 15px rgba(0,0,0,0.22);
}

.stat-card .title-white {
  color: white;
}
.stat-card .title-red {
  color: rgb(184, 45, 45);
}

.stat-card .title-black {
  color: black;
}




/*Dashboard card*/
.dashboard-card {
  margin: 30px auto;
  max-width: 230px;
  height: 145px;
  border-radius: 15px;
/*box-shadow: 5px 5px 30px 7px rgba(0,0,0,0.25), -5px -5px 30px 7px rgba(0,0,0,0.22);*/
  cursor: pointer;
  transition: 0.4s;
  background-color:rgb(248, 243, 243);
  padding: 22px;;
}
.dashb-card-title-text{
  color: rgb(57, 57, 116);
  font-size: 15px;
  font-weight: 700;
}
.dashb-icon{
  font-size: 30px;
  color: rgb(57, 57, 116);

}
.dashb-subtitle{
  color: orange;
  font-size: 10px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  margin-bottom: 0px;

}
.dashb-value{
  color: rgb(57, 57, 116);
  font-size: 22px;
  font-weight: bold;
  margin-top: 0;
}

.dashb-arrow{
  margin-top: 30px;
  color:#008080;
  font-size: 22px;
}

.dashboard-banner-card {
  margin: 30px auto;
  margin-left: 5px;
  max-width:100%;
  height: 100px;
  border-radius: 15px;
  cursor: pointer;
  transition: 0.4s;
  background-color:#008080;
  padding: 22px;
  color: #b4c2c2;
}

.dashboard-banner-card  h3,p{
  color:rgb(221, 212, 212);
}
.dashboard-banner-card  h3{
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  margin-bottom: 0px;
}

.dashboard-banner-card  p{
  font-family: Tahoma, sans-serif;
  font-weight: 400;
  font-size: 14px;
  margin-top: 0px;
  color:rgb(221, 212, 212);
}
.banner-icon{
  margin-right: 0px;
  color:rgb(221, 212, 212);
  font-size: 44px;
}

/*Dashboard card Small*/
.dashboard-card-small {
  margin: 30px auto;
  width: 200px;
  height: 150px;
  border-radius: 15px;
  cursor: pointer;
  transition: 0.4s;
  background-color:rgb(248, 243, 243);
  padding: 14px;;
}
.dashb-small-card-title{
  color:black;
  opacity: 0.7;
  font-size: 16px;
  text-align: left;
}
.dashb-small-card-currency{
  color:rgb(57, 57, 116);
  font-size: 10px;
  font-weight: bold;
  text-align: center;
}

.dashb-small-card-value{
  color:rgb(57, 57, 116);
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  align-items: center;
  margin-bottom: 0px;
}

.dashb-small-card-subtitle{
  color:#008080;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0px;
}
.dashb-small-card-footer-text{
  color:black;
  font-size: 10px;
  text-align: center;
}

/* Section label*/
.section-label{
  color:black;
  opacity: 0.5;
  font-size: 18px;
  text-align: left;
  margin-bottom: 5px;
}

@media all and (max-width: 568px) {
  .card-list {
    /* On small screens, we are no longer using row direction but column */
    flex-direction: column;

  }


.dashb-card-title-text{

  font-size: 12px;
  font-weight: 600px;
}
.dashb-icon{
  display: none;

}

.dashb-value{
  font-size: 16px;
 
}

.dashb-arrow{

  display: none;
}




.dashboard-banner-card  h3{
font-size: 12px;
text-align: left;
}

.dashboard-banner-card  p{
 font-size: 10px;
 text-decoration: left;
 margin: 0px;
}
.banner-icon{
 display: none;
}

/*Dashboard card Small*/
.dashboard-card-small {
  max-width: 150px;
  padding: 12px;;
}
.dashb-small-card-title{

  font-size: 14px;
  
}


.dashb-small-card-value{
  font-size: 24px;

}

.dashb-small-card-subtitle{
  font-size: 10px;
 
}

}

/*#bill-and-invoices .add { margin: -2.5em 0 0; }*/

#bill-and-invoices .add:hover { background: #7fdc68; }

#bill-and-invoices .cut { opacity: 0; position: absolute; top: 0; left: -1.5em; }
#bill-and-invoices .cut { -webkit-transition: opacity 100ms ease-in; }

#bill-and-invoices tr:hover .cut { opacity: 1; }

.gen-report-header{
  margin: 15px;
  background-color: #cbc8c8;
  border: 3px dotted #008080;
  padding: 15px;
  align-items: center;
}

@media print {
	* { -webkit-print-color-adjust: exact; }
	
	#bill-and-invoices span:empty { display: none; }
	#bill-and-invoices .add, .cut { display: none; }
  
}

@page { margin: 0; }

@media print {
  /* Hide buttons for printing */
  button, Button {
    display: none !important;
  }
   /* Hide buttons with a specific class */
   .hide-on-print {
    display: none !important;
  }

  /* Adjust font size for printing */
  table {
    font-size: 10px;
  }

  .card{
    display: none;
  }

}