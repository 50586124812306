@import url(https://fonts.googleapis.com/css?family=Lato:400,700|Montserrat:400,700);
@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.6.3/css/font-awesome.min.css);

.counter-wrapper {
	background: white;
}
.counter-wrapper h1,h2,h3 {
	background-color: transparent;
  color:#008080;
}
.counter-wrapper h3 {
	font-size: 20px;
  border-bottom: yellow 2px solid;
}

/*/ start count stats /*/

section#counter-stats {
	display: flex;
	justify-content: center;
	margin-top: 100px;
}

.stats {
  text-align: center;
  font-size: 35px;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
}

.stats .fa {
  color: #008080;
  font-size: 60px;
}

/*/ end count stats /*/