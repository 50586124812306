.nav-container{
    width:90%;
    margin:auto;
    overflow: hidden;

}
/*header*/
header{
    background-color:#008080;
    color:white;
    padding-top: 30px;
    min-height: 70px;
    border-bottom: rgb(225, 203, 37) 3px solid;

}
header h1{
    background-color: none;
    font-size: 2rem;
}
header a{
    color:#fff;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 16px;


}
header ul{
    margin:0;
    padding: 0;
}

header li{
    float:left;
    display:inline;
    padding: 0 20px 0 20px;
}
header #branding{
    float:left;
}
header #branding h1{
   margin: 0;
   text-decoration: none;
}
header nav{
    float:right;
    margin-top:10px;
}

header .highlight, header .current a{
    color:rgb(225, 203, 37) ;
    font-weight: bold;
}
header a:hover{
    color:#8e8e8e;
    font-weight: bold;

}
header a:visited{
    color:rgb(225, 203, 37);
    font-weight: bold;

}