*, *:before, *:after {
   box-sizing: inherit;
 }
 section{
   margin-top: 20px;
   margin-bottom: 20px;
 }
 
 .column {
   float: left;
   width: 33.3%;
   margin-bottom: 16px;
   padding: 0 8px;
 }
 
 .card {
   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
   margin: 8px;
 }
 
 .about-section {
   padding: 50px;
   text-align: center;
   background-color: #474e5d;
   color: white;
 }
 .about-section h3{
   text-align: left;
   float: left;
   border-bottom: yellow 2px solid;

 }
 
 .container {
   padding: 0 16px;
 }
 
 .container::after, .row::after {
   content: "";
   clear: both;
   display: table;
 }
 
 .title {
   color: grey;
 }
 
 .button {
   border: none;
   outline: 0;
   display: inline-block;
   padding: 8px;
   color: white;
   background-color: rgb(85, 85, 11);
   text-align: center;
   cursor: pointer;
   width: 100%;
 }
 
 .button:hover {
   background-color: #555;
 }
 
 @media screen and (max-width: 650px) {
   .column {
     width: 100%;
     display: block;
   }
 }


#about-us{
    margin: 25px;
}
#about-us .page-title{
    font-size:26px;
    font-style: normal;
    font-weight: 800;
    background-color: transparent;
    text-align: center;
    color:black;
}
#about-us article,aside{
   margin:20px;
   padding:10px;
}
#about-us p{
    font-size: 20px;
    line-height: 22px;
    text-align: justify;
}
aside#vision{
   float:left;
   width: 30%; 
   margin-top: 10px;
}
#mission{
    float:right;
    width: 50%; 
    margin-top: 10px;
 }
 #story{
    float:left;
    width: 30%; 
    margin-top: 10px;
 }

 #value-proposition{
    float:right;
    width: 50%; 
    margin-top: 10px;
 }

 /* -----------------company----------------------- */
.company {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
 }
 
 .company-info {
   display: flex;
   flex-direction: column;
   justify-content: center;
 }
 
 .img {
   width: 100%;
   margin: 1rem 0rem 0rem 2rem;
 }
 
 .img img {
   width: 100%;
 }
 
 .company-info {
   width: 100%;
   margin-right: 4rem;
 }
 
 .company-info span {
   font-size: 2.5rem;
   font-weight: bold;
 }
 
 .company-info span .our {
   color: #ffdc0e;
 }
 
 .company-info p{
   font-size: 1.1rem;
 }
 .timeline {
   position: relative;
   padding: 0;
   list-style: none;
 }
 .timeline:before {
   position: absolute;
   top: 0;
   bottom: 0;
   left: 40px;
   width: 2px;
   margin-left: -1.5px;
   content: "";
   background-color: #e9ecef;
 }
 .timeline > li {
   position: relative;
   min-height: 50px;
   margin-bottom: 50px;
 }
 .timeline > li:after, .timeline > li:before {
   display: table;
   content: " ";
 }
 .timeline > li:after {
   clear: both;
 }
 .timeline > li .timeline-panel {
   position: relative;
   float: right;
   width: 100%;
   padding: 0 20px 0 100px;
   text-align: left;
 }
 .timeline > li .timeline-panel:before {
   right: auto;
   left: -15px;
   border-right-width: 15px;
   border-left-width: 0;
 }
 .timeline > li .timeline-panel:after {
   right: auto;
   left: -14px;
   border-right-width: 14px;
   border-left-width: 0;
 }
 .timeline > li .timeline-image {
   position: absolute;
   z-index: 100;
   left: 0;
   width: 80px;
   height: 80px;
   margin-left: 0;
   text-align: center;
   color: white;
   border: 7px solid #e9ecef;
   border-radius: 100%;
   background-color: #ffc800;
 }
 .timeline > li .timeline-image h4, .timeline > li .timeline-image .h4 {
   font-size: 10px;
   line-height: 14px;
   margin-top: 12px;
 }
 
 .timeline > li.timeline-inverted > .timeline-panel {
   float: right;
   padding: 0 20px 0 100px;
   text-align: left;
 }
 .timeline > li.timeline-inverted > .timeline-panel:before {
   right: auto;
   left: -15px;
   border-right-width: 15px;
   border-left-width: 0;
 }
 .timeline > li.timeline-inverted > .timeline-panel:after {
   right: auto;
   left: -14px;
   border-right-width: 14px;
   border-left-width: 0;
 }
 .timeline > li:last-child {
   margin-bottom: 0;
 }
 .timeline .timeline-heading h4, .timeline .timeline-heading .h4 {
   margin-top: 0;
   color: inherit;
 }
 .timeline .timeline-heading h4.subheading, .timeline .timeline-heading .subheading.h4 {
   text-transform: none;
 }
 .timeline .timeline-body > ul,
 .timeline .timeline-body > p {
   margin-bottom: 0;
 }
 
 @media (min-width: 768px) {
   .timeline:before {
     left: 50%;
   }
   .timeline > li {
     min-height: 100px;
     margin-bottom: 100px;
   }
   .timeline > li .timeline-panel {
     float: left;
     width: 41%;
     padding: 0 20px 20px 30px;
     text-align: right;
   }
   .timeline > li .timeline-image {
     left: 50%;
     width: 100px;
     height: 100px;
     margin-left: -50px;
   }
   .timeline > li .timeline-image h4, .timeline > li .timeline-image .h4 {
     font-size: 13px;
     line-height: 18px;
     margin-top: 16px;
   }
   .timeline > li.timeline-inverted > .timeline-panel {
     float: right;
     padding: 0 30px 20px 20px;
     text-align: left;
   }
 }
 @media (min-width: 992px) {
   .timeline > li {
     min-height: 150px;
   }
   .timeline > li .timeline-panel {
     padding: 0 20px 20px;
   }
   .timeline > li .timeline-image {
     width: 150px;
     height: 150px;
     margin-left: -75px;
   }
   .timeline > li .timeline-image h4, .timeline > li .timeline-image .h4 {
     font-size: 18px;
     line-height: 26px;
     margin-top: 30px;
   }
   .timeline > li.timeline-inverted > .timeline-panel {
     padding: 0 20px 20px;
   }
 }
 @media (min-width: 1200px) {
   .timeline > li {
     min-height: 170px;
   }
   .timeline > li .timeline-panel {
     padding: 0 20px 20px 100px;
   }
   .timeline > li .timeline-image {
     width: 170px;
     height: 170px;
     margin-left: -85px;
   }
   .timeline > li .timeline-image h4, .timeline > li .timeline-image .h4 {
     margin-top: 40px;
   }
   .timeline > li.timeline-inverted > .timeline-panel {
     padding: 0 100px 20px 20px;
   }
 }
 
 .page-section {
   padding: 6rem 0;
 }
 .page-section h2.section-heading, .page-section .section-heading.h2 {
   font-size: 2.5rem;
   margin-top: 0;
   margin-bottom: 1rem;
 }
 .page-section h3.section-subheading, .page-section .section-subheading.h3 {
   font-size: 1rem;
   font-weight: 400;
   font-style: italic;
   font-family: "Roboto Slab", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
   margin-bottom: 4rem;
 }
 
 @media (min-width: 768px) {
   /*section {
     padding: 9rem 0;
   }*/
 }
 
 .text-center {
     text-align: center !important;
 }
 
 .page-section h2.section-heading, .page-section .section-heading.h2 {
     font-size: 2.5rem;
     margin-top: 0;
     margin-bottom: 1rem;
 }
 .text-uppercase {
     text-transform: uppercase !important;
 }
 
 .page-section h3.section-subheading, .page-section .section-subheading.h3 {
     font-size: 1rem;
     font-weight: 400;
     font-style: italic;
     font-family: "Roboto Slab", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
     margin-bottom: 4rem;
 }
 
 .text-muted {
     --bs-text-opacity: 1;
     color: #6c757d !important;
 }
 
 p {
     line-height: 1.75;
 }
 /*@media (min-width: 1400px)
 .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
     max-width: 1320px;
 }
 @media (min-width: 1200px)
 .container-xl, .container-lg, .container-md, .container-sm, .container {
     max-width: 1140px;
 }
 @media (min-width: 992px)
 .container-lg, .container-md, .container-sm, .container {
     max-width: 960px;
 }
 @media (min-width: 768px)
 .container-md, .container-sm, .container {
     max-width: 720px;
 }
 @media (min-width: 576px)
 .container-sm, .container {
     max-width: 540px;
 }*/

 .container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
     width: 100%;
     padding-right: var(--bs-gutter-x, 0.75rem);
     padding-left: var(--bs-gutter-x, 0.75rem);
     margin-right: auto;
     margin-left: auto;
 }
 *, *::before, *::after {
     box-sizing: border-box;
 }
 user agent stylesheet
 div {
     display: block;
 }
 