* {
	box-sizing: border-box;
}
.container-fluid {
	margin-left: 0;
	margin-right: 0;
}
.header {
	height: 100px;
	background-color: darkgreen;
	color: white;
	border: 0.3px solid darkgreen;
}
fieldset {
	border-width: 1.5px;
}
legend {
	width: auto;
	padding: 0 5px;
}
label {
	font-weight: bold;
	font-size: 14px;
}
.c-pointer {
	cursor: pointer;
}
.error {
	color: red;
	font-size: 11px;
	font-weight: bold;
	padding: 3px 0;
	margin-top: -10px;
}

.form-btn{
    background-color: #008080;
    color:#fff;
    text-align: center;
    margin-left: 100px;
    margin-right: 100px;
}
.add-btn{
	width:150px;
	margin-left: 130px;
	background-color: darkgray;
	border-radius: 12px;
	border: #008080;
}


@media all and (max-width: 568px) {
	.add-btn{
		width: 100px;
		margin-left: 40px;
	   border-radius: 10px;
	}
	h2{
		font-size: 12px;
	}
  
  }