.home-page-wrapper{
    margin:0;
    padding: 0;
    background-color: white;
}
/* HERO SECTION */
#showcase{
    min-height: 400px;
    background: url('../../../public/imgs/hero.jpg') no-repeat 0 -400px;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100vh;
    text-align: center;
    align-items: center;
    color:#fff;
    margin:0;
}
#showcase > h1{
    /*margin-top: 100px;*/
    margin-bottom: 10px;
    background-color: transparent;
    font-size: 55px;
    position: relative;
    top:100px;
}
#showcase p{
    font-size: 20px;
    position: relative;
    top:100px;
}
.hero-btn {
    padding-top: 100px;
    background-color: #008080;
    /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 22px;
    border: none;
    border-radius: 10px;
    min-height: 50px;
    min-width: 200px;
    position: relative;
    top:100px;
}
.hero-btn:hover {
    background-color: #c9b9c7;
    transition: 0.7s;
}

.hero-btn:focus {
    outline-color: transparent;
    outline-style: solid;
    box-shadow: 0 0 0 4px #a5d54a;
}

.hero-btn:active {
    background-color: #ffbf00;
}


/* subscribe */
#subscribe{
    padding:16px;
    color:#fff;
    background-color: #008080;

}
#subscribe h1{
    text-align: left;
    margin-top: 10px;
    margin-left:10px;
    background-color: transparent;
    font-size: 30px;
    color:white;
    opacity: 0.8;
    padding-left: 0;
}
#subscribe form{
    float:right;
    margin-top:15px;
    margin-left: auto;
    padding-right: 0;
}
#subscribe input[type="email"]{
    padding:4px;
    height: 30px;
    width:250px;
}
#subscribe .container{
    display: flex;
    flex-direction: row;
}

#subscribe button{
   height: 38px;
   background-color: #ffbf00;
   border:0;
   padding-left: 20px;
   padding-right: 20px;
   padding-bottom: 15px;
   color:#fff;

}

/* Boxes */
#boxes {
    margin-top: 20px;
    margin-bottom: 20px;
}
#boxes h3{
    color:black;
    opacity: 0.8;
    background-color: transparent;
}
#boxes .container{
   display:flex;
   flex-direction: row;
   align-items: center;
}
/*#boxes .box{
    float:left;
    text-align: center;
    width: 30%;
    padding:10px;

}*/
#boxes .box-icon{
    color:#008080;
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    margin-left: 0px;
    }
/* Why choose CS*/
#whycs{
    display:block;
    margin-top:50px;
    margin-bottom:20px;
}

#whycs h1{
    color:#008080;
    opacity: 0.8;
    background-color: transparent;
    font-style: italic;
    text-align: center;
}
#whycs p{
    color:black;
    opacity: 0.7;
    background-color: transparent;
    font-style: italic;
    text-align: center;
    font-size:20px;
    line-height: 16px;
}
#whycs h5{
    color:#008080;
    opacity: 0.8;
    background-color: transparent;
    font-style: italic;
    text-align: left;
    font-size:22px;
    line-height: 16px;
    font-weight: 900;

}
#whycs h6{
    color:black;
    opacity: 0.7;
    text-align: left;
    line-height: 24px;

}
#whycs  img{
    margin:20px;
    width: 50%;
    height:50%;
    margin: 25px;
}
#whycs .content-box{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    border-right: #008080;
    border-top: #ffbf00;
}
/*Our Services */
#ourservices {
    margin-top:50px;

}
#ourservices h2{
    font-size: 30px;
    font-style: italic;
    text-align: center;

}
#ourservices h3{
    font-size: 22px;
    font-style: italic;
    text-align: center;
    background-color: transparent;
    color:#008080;

}

#ourservices .icon{
    font-size: 50px;
    text-align: center;
    background-color: #008080;
    color: #ffbf00;
    border-radius: 50%;

}
#ourservices .service-wrapper{
    display:flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 15px;

}
#ourservices .service-wrapper p{
    
    margin: 15px;
    padding: 10px;

}
/* Service */
.service  {
    margin-top:60px;
    margin-bottom: 50px;
    padding:20px;
    background-color: bisque;
}
.service h2{
    margin-top: 30px;
    background-color: transparent;
    color:black;
    opacity: 0.7;
    font-size: 36px;
    text-align: center;
}
.service p{
    margin-top: 5px;
    color:black;
    opacity: 0.6;
    font-size: 16px;
    text-align: center;
    font-style: italic;
}
.service img{
    width: 400px;
    height: 400px;
    margin-top:20px;
    margin-left:100px;
    margin-right: 5px;
}
.service h4,h5{
    margin-right:20px;
    text-align: justify;
    padding:10px;
}
.service h4{
    font-weight: bold;
    margin-top: 15px;
}
.service h5{
    margin-bottom: 15px;
    line-height: 22px;;
}
.service button{
    float: left;
}

    