.drag-wrapper{
    width: 80%;
    margin-left: 50px;
    margin-right: 50px;
 
    align-items: center;
}
.faq-wrapper{
    background-color: #008080;
    margin-bottom: 20px;
    color:#fff;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin: 15px;
    padding: 25px;
    border-top-right-radius: 50px;

}

 .faq-q{
    text-align: left;
    font-weight: 700;
    font-size: 20px;
}

.faq-a{
    text-align: left;
    padding: 25px;
    font-size: 18px;
    padding-left: 30px;
}

.reorder-btn{
    width: 200px;
    height: 60px;
    margin-top: 50px;
    margin-left: 50%;
    border-radius: 20px;
    padding: 15px;
    text-align: center;
}