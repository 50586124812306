.footer_get_touch_outer {
    margin-top: 80px;
  }
  .container {
    width: 95%;
    max-width: 1140px;
    margin: auto;
  }
  .grid-70-30 {
    display: grid;
    grid-template-columns: 70% 30%;
  }
  .get_form_inner {
    display: block;
    padding: 50px 40px;
    background: #fff;
    box-shadow: -4px -2px 20px -7px #cfd5df;
  }
  input[type="text"], input[type="text"], input[type="email"], input[type="tel"] {
    border: 1px solid #dbdbdb;
    border-radius: 2px;
    color: #333;
    height: 42px;
    padding: 0 0 0 20px;
    width: 100%;
    outline: 0;
  }
  .grid-50-50 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
  .grid-full {
    margin: 20px 0;
  }
  textarea {
    border: 1px solid #dbdbdb;
    border-radius: 2px;
    color: #333;
    padding: 12px 0 0 20px;
    width: 100%;
    outline: 0;
    margin-bottom: 20px;
  }
  .get_form_inner_text h3 {
    color: yellow;
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 40px;
  }
  input[type="submit"] {
    display: inline-block;
    font-size: 16px;
    text-transform: uppercase;
    background: #008080;
    border: none;
    font-weight: 500;
    padding: 10px 20px;
    outline: 0;
    cursor: pointer;
    color: yellow;
    transition: all 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    -webkit-transition: all 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    -moz-transition: all 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    -ms-transition: all 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    -o-transition: all 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  input[type="submit"]:hover {
    background-color:#0dc075;
    border-color: yellow;
    color: #fff;
  }
  .get_say_form {
    display: inline-block;
    padding: 45px 0 25px 30px;
    background: #0dc075;
    position: relative;
  }
  .get_say_form h5 {
    color:antiquewhite;
    font-size: 26px;
    margin: 0 0 40px;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .get_say_social-icn {
    display: flex;
    position: absolute;
    bottom: 40px;
  }
  .get_say_social-icn a {
    font-size: 22px;
    color: #fff;
    padding: 0 20px 0 0;
  }
  .get_say_info_sec i {
    color: #fff;
    font-size: 32px;
  }
  .get_say_info_sec > li {
    display: grid;
    grid-template-columns: 40px auto;
    align-items: center;
    margin-bottom: 40px;
  }
  .get_say_info_sec > li a {
    width: 100%;
    display: block;
    padding: 15px 25px;
    color:antiquewhite;
    font-size: 16px;
    text-decoration: unset;
    font-weight: 500;
    background: #008080;
    border-radius: 5px 0 0 5px;
    transition: background 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    -webkit-transition: background 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    -moz-transition: background 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    -ms-transition: background 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    -o-transition: background 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  .get_say_info_sec > li a:hover {
    background-color: #dfac95;
  }